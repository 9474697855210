import { Injectable } from '@angular/core';
import { MonLog } from '../model/monlogs/mon-log';
import { BaseTableService } from './base-table.service';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MonLogsTableService extends BaseTableService<MonLog> {
  private lastScreen: string;
  private availableTypes: any[];
  private availableGasReleaseTypes: any[];
  private availableCommFailSeverities: any[];
  private availableMonLogWorkTypes: any[];
  private statusOptions: SelectItem[];
  private addFilterOoptions: any;

  constructor() {
    super();
    this.setStatusOptions();
   }

  setLastScreen(screen: string) {
    this.lastScreen = screen;
  }

  getLastScreen() {
    return this.lastScreen;
  }

  getAvailableTypes() {
    return this.availableTypes;
  }

  setAvailableTypes(types: any[]) {
    this.availableTypes = types;
  }

  getAvailableGasReleaseTypeGrades() {
    return this.availableGasReleaseTypes;
  }

  setAvailableGasReleaseTypeGrades(types: any[]) {
    this.availableGasReleaseTypes = types;
  }

  getAvailableCommFailSeverities() {
    return this.availableCommFailSeverities;
  }

  setAvailableCommFailSeverities(types: any[]) {
    this.availableCommFailSeverities = types;
  }

  getAvailableMonLogWorkTypes() {
    return this.availableMonLogWorkTypes;
  }

  setAvailableMonLogWorkTypes(types: any[]) {
    this.availableMonLogWorkTypes = types;
  }

  setStatusOptions(statusOptionsLs: SelectItem[] = null) {
    if (statusOptionsLs == null) {
    this.statusOptions = [
      { label: 'Choose', value: null },
      { label: 'New', value: 'N' },
      { label: 'Resolved', value: 'R' },
      { label: 'Open', value: 'O' },
      { label: 'Review Complete', value: 'C' },
      { label: 'Awaiting Review', value: 'A' }];
    } else {
      this.statusOptions = statusOptionsLs;
    }
  }

  getStatusOptions () {
    return this.statusOptions;
  }

  getAdditionalFilterOptions(): any {
    return this.addFilterOoptions;
  }

  setAdditionalFilterOptions(options: any): void {
    this.addFilterOoptions = options;
  }
}
